<template>
  <div>
    <div v-if="Loaderstatus" class="relativeclass">
      <div class="spinnerstyle">
        <Spinner />
      </div>
    </div>
    <div class="mobileScreen-main-container">
      <MobileHeader v-b-modal.mobileCaptureError />

      <div class="mobileScreen-main-innerContainer">
        <div class="m-3">
          <div class="mobileScreen-TD-head">
            Add Your Snapshot
          </div>
          <div class="mt-1 mobile-login-sub-font">
            This process request you to provide a snapshot of yourself, as well
            as a snapshot of your ID card.
          </div>
        </div>

        <div class="mobileScreen-snapshot-mainBody m-3 mt-4">
          <div>
            <div
              class="mobileScreen-TD-body-subhead"
              v-show="idCaptureView === false"
            >
              Provide your snapshot
            </div>
            <div
              class="mobileScreen-TD-body-subhead"
              v-show="idCaptureView === true"
            >
              ID Proof
            </div>
            <div
              class="mobileScreen-snapshot-imagebox mt-4 relativeclass"
              style="height: 250px;"
              v-show="idCaptureView === false"
            >
              <div class="snapshot-user-image-click">
                <video
                  style="transform: scaleX(-1); object-fit: cover;"
                  class="w-100 h-100 mobileScreen-snapshot-image"
                  id="selfieCam"
                  autoplay
                  playsinline
                  muted
                ></video>
                <canvas
                  class="w-100 h-100 mobileScreen-snapshot-image"
                  id="canvas"
                  style="transform: scaleX(-1); object-fit: cover"
                  hidden
                ></canvas>
                <div>
                  <div
                    v-if="isPhotoTaken === false"
                    style="position: absolute; top: 45%; right: 47%;"
                  >
                    <img
                      :style="isCameraOpen ? 'display: none;' : ''"
                      src="../../assets/mobileScreenIcons/video-selfie-image.svg"
                      alt=""
                    />
                  </div>
                  <div
                    class="w-100"
                    style="position: absolute; top: 0; transform: scaleX(-1);"
                    v-else
                    id="image"
                  >
                    <img
                      class="w-100"
                      style="border-radius: 13px; height: 250px; object-fit: cover;"
                      :src="selfieSrc"
                      alt=""
                    />
                  </div>
                </div>
                <div
                  class="mobileScreen-selfie-cam"
                  @click="startStream()"
                  v-show="isCameraOpen === false"
                >
                  <img
                    src="../../assets/mobileScreenIcons/selfie-camera.svg"
                    alt=""
                  />
                </div>
                <b-overlay :show="isProcessing">
                  <div
                    class="mobileScreen-selfie-cam"
                    @click="takePhoto()"
                    v-show="isCameraOpen === true"
                  >
                    <img
                      src="../../assets/mobileScreenIcons/selfie-camera.svg"
                      alt=""
                    />
                  </div>
                </b-overlay>
              </div>
            </div>
            <div
              class="mobileScreen-snapshot-imagebox mt-4 relativeclass"
              style="height: 250px;"
              v-show="idCaptureView === true"
            >
              <div class="snapshot-user-image-click">
                <video
                  style="object-fit: cover; transform: scaleX(-1);"
                  class="w-100 h-100 mobileScreen-snapshot-image"
                  id="selfieCamID"
                  autoplay
                  playsinline
                  muted
                ></video>
                <canvas
                  class="w-100 h-100 mobileScreen-snapshot-image"
                  id="canvasID"
                  style="object-fit: cover; transform: scaleX(-1);"
                  hidden
                ></canvas>
                <div>
                  <div
                    v-if="idisPhotoTaken === false"
                    style="position: absolute; top: 45%; right: 47%;"
                  >
                    <img
                      :style="idisCameraOpen ? 'display: none;' : ''"
                      src="../../assets/mobileScreenIcons/video-id-image.svg"
                      alt=""
                    />
                  </div>
                  <div
                    class="w-100"
                    style="position: absolute; top: 0;"
                    v-else
                    id="image"
                  >
                    <img
                      class="w-100"
                      style="border-radius: 13px; height: 250px; object-fit: cover; transform: scaleX(-1);"
                      :src="idSrc"
                      alt=""
                    />
                  </div>
                </div>
                <div
                  class="mobileScreen-selfie-cam"
                  @click="startStreamForId()"
                  v-show="idisCameraOpen === false"
                >
                  <img
                    src="../../assets/mobileScreenIcons/selfie-camera.svg"
                    alt=""
                  />
                </div>
                <div
                  class="mobileScreen-selfie-cam"
                  @click="idTakePhoto()"
                  v-show="idisCameraOpen === true"
                >
                  <img
                    src="../../assets/mobileScreenIcons/selfie-camera.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <div class="mobileScreen-TD-body-subhead">
              Instructions
            </div>
            <div class="mt-3">
              <!-- these instructions are for selfie -->
              <div v-show="idCaptureView === false">
                <ul>
                  <li class="mt-1 mobile-login-sub-li-font">
                    Please check that there shouldn't be people or any objects
                    in your background.
                  </li>
                  <li class="mt-1 mobile-login-sub-li-font">
                    You should be close enough to the camera to capture the
                    clear face.
                  </li>
                  <li class="mt-1 mobile-login-sub-li-font">
                    Clean your camera lens.
                  </li>
                </ul>
              </div>
              <div v-show="idCaptureView === true">
                <!-- these instructions are for ID -->
                <ul>
                  <li class="mt-1 mobile-login-sub-li-font">
                    Capture clear, high-quality documents.
                  </li>
                  <li class="mt-1 mobile-login-sub-li-font">
                    Make sure you upload a valid ID proof.
                  </li>
                  <li class="mt-1 mobile-login-sub-li-font">
                    Ensure that the documents are clear, readable, and in good
                    lighting condition.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          class="mobileScreen-TD-footer d-flex align-items-center justify-content-between bluebtns"
        >
          <b-button
            class="mobileScreen-leaveBtn mr-3"
            @click="$bvModal.show('mobileLeaveTest')"
            >Leave Test</b-button
          >
          <div v-show="idCaptureView === false">
            <b-button class="mobileScreen-startBtn" @click="goToNext()"
              >Next</b-button
            >
          </div>
          <div v-show="idCaptureView === true">
            <b-button class="mobileScreen-startBtn" @click="proceedToNext()"
              >Proceed</b-button
            >
          </div>
        </div>
      </div>
    </div>

    <!-- capture error modal starts from here -->
    <b-modal
      id="mobileCaptureError"
      centered
      hide-footer
      hide-header
      class="p-0"
    >
      <div class="d-flex align-items-center justify-content-center flex-column">
        <div>
          <img
            src="../../assets/mobile-newUI-imgs/new-warning-icon.svg"
            alt=""
          />
        </div>

        <div class="mt-4 mobileScreen-modal-head">
          Captured image is not accurate.
        </div>

        <div class="mt-2">
          <ul class="mobileScreen-modal-subhead">
            <li>
              Please make sure there is no person or object in your background
            </li>
            <li>
              You should be close enough to the camera to capture the face.
            </li>
            <li>
              Clean the lens and re-capture,
            </li>
          </ul>
        </div>
        <div class="mt-4 mobileScreen-TS-secName text-center w-50">
          <b-button
            class="mobileScreen-startBtn w-100"
            @click="$bvModal.hide('mobileCaptureError')"
            >Okay</b-button
          >
        </div>
      </div>
    </b-modal>

    <!-- leave error modal starts from here -->
    <b-modal id="mobileLeaveTest" centered hide-footer hide-header class="p-0">
      <div class="d-flex align-items-center justify-content-center flex-column">
        <div>
          <img
            src="../../assets/mobile-newUI-imgs/new-warning-icon.svg"
            alt=""
          />
        </div>

        <div class="mt-4 mobileScreen-modal-head">
          Alert!
        </div>

        <div class="mt-3 mobileScreen-modal-subhead text-center">
          Are you sure you want to leave the test ? <br />
          **Please note this action cannot be undone**
        </div>

        <div
          class="mt-4 bluebtns d-flex align-items-center justify-content-center flex-column w-100"
        >
          <div class="w-50">
            <b-button
              class="mobileScreen-startBtn w-100"
              @click="$bvModal.hide('mobileLeaveTest')"
              >Cancel</b-button
            >
          </div>
          <div class="w-50">
            <b-button
              @click="leaveTest()"
              class="mobileScreen-leaveBtn mt-3 w-100"
              >Leave Test</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="mobilecapturealert"
      centered
      hide-footer
      hide-header
      class="p-0"
    >
      <div
        class="m-4 d-flex align-items-center justify-content-center flex-column"
      >
        <div>
          <img
            src="../../assets/mobile-newUI-imgs/new-warning-icon.svg"
            alt=""
          />
        </div>

        <div class="mt-4 mobileScreen-modal-head">
          Alert!
        </div>

        <div class="mt-3 mobileScreen-modal-subhead text-center">
          please capture the picture to continue.
        </div>

        <div class="mt-4 mobileScreen-TS-secName text-center w-50">
          <b-button
            class="mobileScreen-startBtn w-100"
            @click="$bvModal.hide('mobilecapturealert')"
            >Okay</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MobileHeader from "./MobileHeader.vue";
import { candidateImage } from "../../apiFunction";
import Webcam from "webcam-easy";
import Spinner from "../../components/fixedcomponents/Spinner.vue";
import axios from "axios";

const snapShot = {
  data() {
    return {
      osCheckforModel: "",
      isProcessing: false,
      Loaderstatus: false,
      showMob: false,
      isCameraOpen: false,
      isPhotoTaken: false,
      idCaptureView: false,
      idisCameraOpen: false,
      idisPhotoTaken: false,
      capturedImage: null,
      capturedIdimage: null,
      selfieSrc: null,
      idSrc: null,
      topicid: "",
      testid: "",
      userid: "",
    };
  },
  components: {
    MobileHeader,
    Spinner,
  },
  created() {
    this.askCameraPermission();
    this.topicid = this.testDetails.topicID;
    this.testid = this.testDetails.testID;
    this.userid = this.userID;
    let width = window.innerWidth;
    if (width > 820) {
      console.log(width);
      this.showMob = false;
    }
    if (width < 820) {
      this.showMob = true;
    }
    this.osCheckforModel = navigator.userAgentData.platform;
  },
  mounted() {},
  computed: {
    ...mapGetters("sectionVUEX", {
      isLoadingStatus: "getIsLoadingStatus",
      testDetails: "getTestDetails",
      userID: "getUserID",
      userDetials: "getUserDetails",
      uuid: "getUUID",
    }),
  },
  methods: {
    askCameraPermission() {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: true,
        })
        .then(
          (res) => console.log(res),
          (err) => {
            console.log(err);
          }
        );
    },

    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    startStream() {
      const canvasElement = document.getElementById("canvas");
      const webcamElement = document.getElementById("selfieCam");
      const webcam = new Webcam(webcamElement, "user", canvasElement);
      webcam.start();
      this.isCameraOpen = true;
    },

    startStreamForId() {
      const canvasElement = document.getElementById("canvasID");
      const webcamElement = document.getElementById("selfieCamID");
      const webcam = new Webcam(webcamElement, "user", canvasElement);
      webcam.start();
      this.idisCameraOpen = true;
    },

    async takePhoto() {
      try {
        this.isProcessing = true;
        this.isPhotoTaken = !this.isPhotoTaken;
        const canvasElement = document.getElementById("canvas");
        const webcamElement = document.getElementById("selfieCam");
        const webcam = new Webcam(webcamElement, "user", canvasElement);

        canvasElement.width = webcamElement.videoWidth;
        canvasElement.height = webcamElement.videoHeight;

        const context = canvasElement.getContext("2d");

        const videoAspectRatio =
          webcamElement.videoWidth / webcamElement.videoHeight;
        const canvasAspectRatio = canvasElement.width / canvasElement.height;

        let sx, sy, sWidth, sHeight;

        if (canvasAspectRatio > videoAspectRatio) {
          sWidth = webcamElement.videoWidth;
          sHeight = webcamElement.videoWidth / canvasAspectRatio;
          sx = 0;
          sy = (webcamElement.videoHeight - sHeight) / 2;
        } else {
          sWidth = webcamElement.videoHeight * canvasAspectRatio;
          sHeight = webcamElement.videoHeight;
          sx = (webcamElement.videoWidth - sWidth) / 2;
          sy = 0;
        }

        context.drawImage(
          webcamElement,
          sx,
          sy,
          sWidth,
          sHeight,
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );

        webcam.stop();
        this.selfieSrc = canvasElement.toDataURL("image/png");
        let file = this.dataURLtoFile(this.selfieSrc, "picture.png");
        this.capturedImage = file;

        if (this.isPhotoTaken === true) {
          const { CancelToken } = axios;
          const source = CancelToken.source();
          setTimeout(() => {
            source.cancel("Request canceled due to timeout");
          }, 30000);
          let formData = new FormData();
          formData.append("image", file);
          let res = await axios.post(
            "https://proctor.simplifymycareer.com/face_detection/",
            formData,
            {
              cancelToken: source.token,
            }
          );
          this.isProcessing = false;
          if (res.data.score * 100 >= 75) {
            this.isPhotoTaken = true;
          } else {
            this.isProcessing = false;
            this.isPhotoTaken = false;
            this.$bvModal.show("mobileCaptureError");
          }
        } else {
          this.isProcessing = false;
          this.isPhotoTaken = false;
        }
      } catch (error) {
        this.isProcessing = false;
        this.isPhotoTaken = true;
        return error;
      }
    },
    idTakePhoto() {
      this.idisPhotoTaken = !this.idisPhotoTaken;
      const canvasElement = document.getElementById("canvasID");
      const webcamElement = document.getElementById("selfieCamID");
      const webcam = new Webcam(webcamElement, "user", canvasElement);

      canvasElement.width = webcamElement.videoWidth;
      canvasElement.height = webcamElement.videoHeight;

      const context = canvasElement.getContext("2d");

      const videoAspectRatio =
        webcamElement.videoWidth / webcamElement.videoHeight;
      const canvasAspectRatio = canvasElement.width / canvasElement.height;

      let sx, sy, sWidth, sHeight;

      if (canvasAspectRatio > videoAspectRatio) {
        sWidth = webcamElement.videoWidth;
        sHeight = webcamElement.videoWidth / canvasAspectRatio;
        sx = 0;
        sy = (webcamElement.videoHeight - sHeight) / 2;
      } else {
        sWidth = webcamElement.videoHeight * canvasAspectRatio;
        sHeight = webcamElement.videoHeight;
        sx = (webcamElement.videoWidth - sWidth) / 2;
        sy = 0;
      }

      context.drawImage(
        webcamElement,
        sx,
        sy,
        sWidth,
        sHeight,
        0,
        0,
        canvasElement.width,
        canvasElement.height
      );

      webcam.stop();
      this.idSrc = canvasElement.toDataURL("image/png");
      let file = this.dataURLtoFile(this.idSrc, "Idpicture.png");
      this.capturedIdimage = file;
    },

    proceedToNext() {
      this.Loaderstatus = true;
      let formData = new FormData();
      let topicId = this.topicid;
      let testID = this.testid;
      formData.append("topicID", topicId);
      formData.append("testID", testID);
      formData.append("groupID", this.testDetails.groupID);
      formData.append("candidateID", this.userID);
      formData.append("candidateImageWithID", this.capturedIdimage);
      formData.append("emailID", this.userDetials.email);
      formData.append("attemptNo", this.testDetails.attemptNo);
      if (this.idisPhotoTaken) {
        candidateImage(formData).then(() => {
          this.idCaptureView = false;
          this.Loaderstatus = false;
          if (this.testDetails.config.communicationTest === true) {
            this.$router.push({
              path: "/communicationtest",
            });
          } else {
            this.$router.push({
              path: "/dosanddonts",
            });
          }
        });
      } else {
        this.$bvModal.show("mobilecapturealert");
        this.Loaderstatus = false;
      }
    },

    goToNext() {
      this.Loaderstatus = true;
      let formData = new FormData();
      let topicId = this.topicid;
      let testID = this.testid;
      formData.append("topicID", topicId);
      formData.append("testID", testID);
      formData.append("groupID", this.testDetails.groupID);
      formData.append("candidateID", this.userID);
      formData.append("candidateImage", this.capturedImage);
      formData.append("emailID", this.userDetials.email);
      formData.append("attemptNo", this.testDetails.attemptNo);
      if (this.isPhotoTaken) {
        candidateImage(formData).then(() => {
          this.idCaptureView = true;
          this.Loaderstatus = false;
        });
      } else {
        this.$bvModal.show("mobilecapturealert");
        this.Loaderstatus = false;
      }
    },

    leaveTest() {
      if (localStorage.getItem("fromCandidate")) {
        window.close("", "_parent", "");
      }
      this.$router.push({
        path: "/",
        query: {
          uuid: this.uuid,
        },
      });
    },
  },
};
export default snapShot;
</script>

<style scoped>
#mobileselfieCam,
#midCam {
  border: 5px solid gray;
}

#photoTaken,
#idphotoTaken {
  border: 5px solid green;
}
</style>
